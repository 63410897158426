
import Vue from "@/interfaces/vue";
import { Component, Prop, PropSync } from "vue-property-decorator";
import { Fragment } from "vue-fragment";
import Sidebar from "@/components/layout/Sidebar.vue";

@Component({ components: { Fragment, Sidebar } })
export default class SidebarDropdown extends Vue {
  @Prop()
  mainOption!: { icon: string; name: string; link: string };

  @Prop()
  options!: { name: string; link: string }[];

  @PropSync("mobileOptions")
  _mobileOptions!: { name: string; link: string }[];
  @PropSync("showMobileOptions")
  _showMobileOptions!: boolean;

  @Prop({ default: true })
  wide!: boolean;

  @Prop({ default: false })
  redirect!: boolean;

  collapsed = true;

  get style() {
    return this.wide ? "wide" : "compact";
  }

  mobileMainItemClick() {
    if (!this._showMobileOptions) {
      this._mobileOptions = this.options;
      this._showMobileOptions = true;
    } else this._showMobileOptions = false;
  }

  mainItemClick() {
    if (this.redirect) this.$router.push(this.mainOption.link);
    else this.collapsed = !this.collapsed;
  }
}
