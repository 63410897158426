
import Vue from "@/interfaces/vue";
import { Component } from "vue-property-decorator";
import { mixin as clickaway } from "vue-clickaway";

@Component({
  mixins: [clickaway],
})
export default class DropdownMenu extends Vue {
  show = false;

  changeShow(newShow: boolean) {
    this.show = newShow;
  }

  changeShowToFalse() {
    if (this.show) this.changeShow(false);
  }
}
