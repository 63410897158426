var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[(_vm.user)?_c('div',{staticClass:"sidebar",class:{ wide: _vm.wide || !_vm.compact, compact: !_vm.wide && _vm.compact }},[_c('div',{staticClass:"hamburger"},[(_vm.compact)?_c('label',{staticClass:"fas fa-bars",attrs:{"for":"width"}}):_vm._e(),(_vm.showUserName)?_c('div',[_vm._v(" "+_vm._s(_vm.userName)+" ")]):_vm._e(),_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.wide),expression:"wide"}],attrs:{"id":"width","type":"checkbox"},domProps:{"checked":Array.isArray(_vm.wide)?_vm._i(_vm.wide,null)>-1:(_vm.wide)},on:{"change":function($event){var $$a=_vm.wide,$$el=$event.target,$$c=$$el.checked?(true):(false);if(Array.isArray($$a)){var $$v=null,$$i=_vm._i($$a,$$v);if($$el.checked){$$i<0&&(_vm.wide=$$a.concat([$$v]))}else{$$i>-1&&(_vm.wide=$$a.slice(0,$$i).concat($$a.slice($$i+1)))}}else{_vm.wide=$$c}}}})]),_c('div',{staticClass:"options"},[_c('router-link',{staticClass:"hide-mobile",attrs:{"to":"/"}},[_c('i',{staticClass:"fas fa-home"}),_c('div',[_vm._v(" "+_vm._s(_vm.$t("sidebar.home"))+" ")])]),_c('router-link',{attrs:{"to":"/dashboard"}},[_c('i',{staticClass:"fas fa-columns"}),_c('div',[_vm._v(" "+_vm._s(_vm.$t("common.dashboard"))+" ")])]),_c('router-link',{attrs:{"to":"/dashboard/messenger"}},[_c('i',{staticClass:"fas fa-envelope-open"}),_c('div',[_vm._v(" "+_vm._s(_vm.$t("sidebar.inbox"))+" ")])]),(!_vm.user.is_therapist)?_c('fragment',[_c('router-link',{attrs:{"to":"/dashboard/chatbot"}},[_c('i',{staticClass:"fas fa-comments"}),_c('div',[_vm._v(" "+_vm._s(_vm.$t("sidebar.chatbot"))+" ")])]),_c('sidebar-dropdown',{attrs:{"mainOption":{
            icon: 'fas fa-handshake',
            name: _vm.$t('sidebar.my-visits'),
            link: '/dashboard/patient-visits/pending',
          },"options":[
            {
              name: _vm.$t('sidebar.pending'),
              link: '/dashboard/patient-visits/pending',
            },
            {
              name: _vm.$t('sidebar.history'),
              link: '/dashboard/patient-visits/history',
            },
            {
              name: _vm.$t('sidebar.register'),
              link: '/therapists',
            } ],"wide":_vm.wide,"redirect":_vm.dropdownRedirect,"mobileOptions":_vm.mobileOptions,"showMobileOptions":_vm.showMobileOptions},on:{"update:mobileOptions":function($event){_vm.mobileOptions=$event},"update:mobile-options":function($event){_vm.mobileOptions=$event},"update:showMobileOptions":function($event){_vm.showMobileOptions=$event},"update:show-mobile-options":function($event){_vm.showMobileOptions=$event}}}),_c('sidebar-dropdown',{attrs:{"mainOption":{
            icon: 'fas fa-chalkboard',
            name: _vm.$t('sidebar.challenges'),
            link: '/dashboard/purchased-challenges',
          },"options":[
            {
              name: _vm.$t('sidebar.purchased'),
              link: '/dashboard/purchased-challenges',
            },
            {
              name: _vm.$t('sidebar.browse-all'),
              link: '/challenges',
            } ],"wide":_vm.wide,"redirect":_vm.dropdownRedirect,"mobileOptions":_vm.mobileOptions,"showMobileOptions":_vm.showMobileOptions},on:{"update:mobileOptions":function($event){_vm.mobileOptions=$event},"update:mobile-options":function($event){_vm.mobileOptions=$event},"update:showMobileOptions":function($event){_vm.showMobileOptions=$event},"update:show-mobile-options":function($event){_vm.showMobileOptions=$event}}})],1):_c('fragment',[_c('router-link',{attrs:{"to":"/dashboard/patients"}},[_c('i',{staticClass:"fas fa-users-medical"}),_c('div',[_vm._v(_vm._s(_vm.$t("sidebar.patients")))])]),_c('router-link',{staticClass:"hide-mobile",attrs:{"to":"/dashboard/settlements"}},[_c('i',{staticClass:"fas fa-money-bill"}),_c('div',[_vm._v(" "+_vm._s(_vm.$t("common.settlements"))+" ")])]),_c('sidebar-dropdown',{staticClass:"hide-mobile",attrs:{"mainOption":{
            icon: 'fas fa-user',
            name: _vm.$t('sidebar.my-profile'),
            link: '/dashboard/myprofile/personal',
          },"options":[
            {
              name: _vm.$t('sidebar.personal-data'),
              link: '/dashboard/myprofile/personal',
            },
            {
              name: _vm.$t('sidebar.background'),
              link: '/dashboard/myprofile/background',
            },
            {
              name: _vm.$t('sidebar.office'),
              link: '/dashboard/myprofile/office',
            },
            {
              name: _vm.$t('sidebar.availability'),
              link: '/dashboard/myprofile/availability',
            },
            {
              name: _vm.$t('sidebar.billing'),
              link: '/dashboard/myprofile/billing',
            } ],"wide":_vm.wide,"redirect":_vm.dropdownRedirect,"mobileOptions":_vm.mobileOptions,"showMobileOptions":_vm.showMobileOptions},on:{"update:mobileOptions":function($event){_vm.mobileOptions=$event},"update:mobile-options":function($event){_vm.mobileOptions=$event},"update:showMobileOptions":function($event){_vm.showMobileOptions=$event},"update:show-mobile-options":function($event){_vm.showMobileOptions=$event}}}),_c('sidebar-dropdown',{attrs:{"mainOption":{
            icon: 'fas fa-handshake',
            name: _vm.$t('sidebar.my-visits'),
            link: '/dashboard/visits/pending',
          },"options":[
            {
              name: _vm.$t('sidebar.pending'),
              link: '/dashboard/visits/pending',
            },
            {
              name: _vm.$t('sidebar.history'),
              link: '/dashboard/visits/history',
            } ],"wide":_vm.wide,"redirect":_vm.dropdownRedirect,"mobileOptions":_vm.mobileOptions,"showMobileOptions":_vm.showMobileOptions},on:{"update:mobileOptions":function($event){_vm.mobileOptions=$event},"update:mobile-options":function($event){_vm.mobileOptions=$event},"update:showMobileOptions":function($event){_vm.showMobileOptions=$event},"update:show-mobile-options":function($event){_vm.showMobileOptions=$event}}}),_c('sidebar-dropdown',{attrs:{"mainOption":{
            icon: 'far fa-calendar',
            name: _vm.$t('sidebar.calendar'),
            link: '/dashboard/calendar-availability',
          },"options":[
            {
              name: _vm.$t('sidebar.calendar-available'),
              link: '/dashboard/calendar-availability',
            },
            {
              name: _vm.$t('sidebar.calendar-booked'),
              link: '/dashboard/calendar-picked',
            } ],"wide":_vm.wide,"redirect":_vm.dropdownRedirect,"mobileOptions":_vm.mobileOptions,"showMobileOptions":_vm.showMobileOptions},on:{"update:mobileOptions":function($event){_vm.mobileOptions=$event},"update:mobile-options":function($event){_vm.mobileOptions=$event},"update:showMobileOptions":function($event){_vm.showMobileOptions=$event},"update:show-mobile-options":function($event){_vm.showMobileOptions=$event}}}),_c('sidebar-dropdown',{staticClass:"hide-mobile",attrs:{"mainOption":{
            icon: 'fas fa-chalkboard',
            name: _vm.$t('sidebar.challenges'),
            link: '/dashboard/purchased-challenges',
          },"options":[
            {
              name: _vm.$t('sidebar.purchased'),
              link: '/dashboard/purchased-challenges',
            },
            {
              name: _vm.$t('sidebar.browse-all'),
              link: '/challenges',
            } ],"wide":_vm.wide,"redirect":_vm.dropdownRedirect,"mobileOptions":_vm.mobileOptions,"showMobileOptions":_vm.showMobileOptions},on:{"update:mobileOptions":function($event){_vm.mobileOptions=$event},"update:mobile-options":function($event){_vm.mobileOptions=$event},"update:showMobileOptions":function($event){_vm.showMobileOptions=$event},"update:show-mobile-options":function($event){_vm.showMobileOptions=$event}}})],1)],1),(_vm.block)?_c('div',{staticClass:"block"},[_c('i',{staticClass:"fas fa-lock"})]):_vm._e(),_c('div',{staticClass:"logo"},[_c('router-link',{attrs:{"to":"/"}},[_c('img',{attrs:{"src":require("@/assets/logo-white.png"),"alt":"Calmsie logo","id":"logo"}})])],1)]):_vm._e(),(_vm.showFiller)?_c('div',{staticClass:"black-filler"}):_vm._e(),_c('div',{staticClass:"mobile-options",class:{ show: _vm.showMobileOptions }},_vm._l((_vm.mobileOptions),function(option){return _c('router-link',{key:option.name,attrs:{"to":option.link},nativeOn:{"click":function($event){_vm.showMobileOptions = false}}},[_vm._v(" "+_vm._s(option.name)+" ")])}),1)])}
var staticRenderFns = []

export { render, staticRenderFns }