
import Vue from "@/interfaces/vue";
import { Component, Prop } from "vue-property-decorator";
import { Fragment } from "vue-fragment";
import SidebarDropdown from "@/components/SidebarDropdown.vue";

@Component({
  components: { Fragment, SidebarDropdown },
})
export default class Sidebar extends Vue {
  @Prop({ default: false })
  compact!: boolean;

  wide = false;

  mobileOptions: any[] = [];

  showMobileOptions = false;

  get user() {
    return this.$store.state.user;
  }

  get block() {
    return !this.$store.state.isFilled;
  }

  get showUserName() {
    return !this.compact || (this.compact && this.wide);
  }

  get userName() {
    if (this.user) return this.user.first_name + " " + this.user.last_name;
    else return "";
  }

  get showFiller() {
    return (this.compact && this.wide) || this.showMobileOptions;
  }

  get dropdownRedirect() {
    if (this.compact && !this.wide) return true;
    else return false;
  }

  created() {
    this.wide = !this.compact;
  }
}
